<template>
  <section>
    <div v-if="!checkEmailTemplateExists()">Please Select Template</div>
    <div v-else>
      <div
        class="size-selector"
        v-if="this.currentEntity.viewType != 'HTML_CONTENT'"
      >
        <label for="cardHeight">Card Height:</label>
        <input
          type="range"
          id="cardHeight"
          min="100"
          max="700"
          v-model="cardHeight"
        />
        <span>{{ cardHeight }}px</span>
      </div>
      <el-row style="display: flex; flex-direction: row;">
        <!-- First Block: Display all templates -->
        <el-col :span="4" style="overflow-y: auto;">
          <el-scrollbar wrap-style="min-height: 70vh;overflow-y: auto;">
            <div class="card-summary">
              <div
                v-for="(template, index) in !searchQuery
                  ? firstBlockData
                  : this.searchData"
                :key="index"
                class="summary"
                @click="selectedCard(template)"
                :style="{
                  backgroundColor: selectedRecord._id === template._id ? '#0091D5' : 'white',
                  color: selectedRecord._id === template._id ? 'white' : ''
                }"
              >
                <div v-if="template.field === 'title'">
                  <span>Title:</span>{{ template.fieldValue }}
                </div>
                <div v-else-if="template.field === 'description'">
                  <span>Description:</span>{{ template.fieldValue }}
                </div>
                <div v-else>
                  <div
                    v-if="
                      getFieldKey('title') && getFieldValue('title', template)
                    "
                  >
                    <span class="bold-key">{{ getFieldKey("title") }} </span>:
                    <span class="card-data">{{
                      getFieldValue("title", template) | truncate(140, "...")
                    }}</span>
                  </div>
                  <div
                    v-if="
                      getFieldKey('description') &&
                      getFieldValue('description', template)
                    "
                  >
                    <span class="bold-key"
                      >{{ getFieldKey("description") }} </span
                    >:
                    <span class="card-data">{{
                      getFieldValue("description", template)
                        | truncate(140, "...")
                    }}</span>
                  </div>
                </div>
              </div>
            </div>
          </el-scrollbar>
        </el-col>
        <!-- Second Block: Display selected  template details -->
        <el-col :span="6" style="max-height: 60vh">
          <div style="overflow-y: hidden">
            <el-scrollbar wrap-style="max-height: calc(100vh - 180px)">
              <div
                class="cards"
                v-if="
                  selectedRecord &&
                  (searchQuery != '' || middleDivFilterData.length)
                "
              >
                <div
                  v-for="(row, index) in searchQuery != ''
                    ? searchFilterData
                    : middleDivFilterData"
                  :key="index"
                  style="overflow-x: hidden"
                >
                  <div
                    class="entity-data-card"
                    v-if="
                      getProfilePicture(row) ||
                      getFieldValue('profile', row) ||
                      getFieldValue('title', row) ||
                      getFieldValue('description', row) ||
                      getFieldValue('field1', row) ||
                      getFieldValue('field2', row) ||
                      getFieldValue('field3', row)
                    "
                    @dblclick="cellDoubleClicked(row)"
                  >
                    <span
                      v-if="
                        isEditing.title ||
                        isEditing.description ||
                        isEditing.field1 ||
                        isEditing.field2 ||
                        isEditing.field3
                      "
                      class="edit-icons"
                    >
                      <i class="tick-icon" @click="doubleClickUpdate(row)"
                        >✔️</i
                      >
                      <i class="cross-icon" @click="cancelEditAll">❌</i>
                    </span>
                    <!-- <div class="card-profile">
                      <img
                        v-if="getProfilePicture(row)"
                        :src="getProfilePicture(row)"
                        style="border-radius: 50%"
                      />
                      <imgawai
                        v-if="getFieldValue('profile', row)"
                        :src="getFieldValue('profile', row)"
                        alt="icon"
                        style="border-radius: 50%"
                      />
                      <div v-else>
                        <img
                          v-if="currentEntity.entity_type == 'INDIVIDUAL'"
                          src="@/assets/img/defaultAvatar/Individual.svg"
                          alt="icon"
                          style="border-radius: 50%"
                        />
                        <img
                          v-else
                          src="@/assets/img/defaultAvatar/Business.svg"
                          alt="icon"
                        />
                      </div> -->
                    <!-- </div> -->
                    <div class="card-body">
                      <p
                        v-if="
                          getFieldKey('title') && getFieldValue('title', row)
                        "
                        class="card-title"
                        style="display: flex;"
                      >
                        <span class="bold-key">{{ getFieldKey("title") }} </span
                        >:
                        <span v-if="!isEditing.title" class="card-data">{{
                          getFieldValue("title", row) | truncate(140, "...")
                        }}</span>
                        <span v-else>
                          <input
                            v-if="
                              editableFields_Types.title !== 'SELECT' &&
                              editableFields_Types.title !== 'DATE_TIME'
                            "
                            v-model="editableFields.title"
                            class="edit-input"
                          />
                        </span>

                        <el-select
                          v-if="
                            editableFields_Types.title === 'SELECT' &&
                            isCancelEditAll
                          "
                          v-model="editableFields.title"
                           size="small"
                          style="margin-left: 4px;"
                        >
                          <el-option
                            v-for="option in options[getFieldKey('title')]"
                            :value="option"
                            :key="option"
                          >
                            {{ option }}
                          </el-option>
                        </el-select>
                        <el-date-picker
                          v-if="
                            editableFields_Types.title === 'DATE_TIME' &&
                            isCancelEditAll
                          "
                          style="margin-left: 4px;"
                          v-model="myDate"
                          type="datetime"
                          placeholder="Pick a date and time"
                          format="dd-MM-yyyy HH:mm:ss"
                          value-format="dd-MM-yyyy HH:mm:ss"
                          @change="handleDateChange"
                        />
                      </p>

                      <p
                        v-if="
                          getFieldKey('description') &&
                          getFieldValue('description', row)
                        "
                        class="card-description header"
                         style="display: flex;"
                      >
                        <span class="bold-key"
                          >{{ getFieldKey("description") }} </span
                        >:
                        <span v-if="!isEditing.description" class="card-data">{{
                          getFieldValue("description", row)
                            | truncate(140, "...")
                        }}</span>
                        <span v-else>
                          <input
                            v-if="
                              editableFields_Types.description !== 'SELECT' &&
                              editableFields_Types.description !== 'DATE_TIME'
                            "
                            v-model="editableFields.description"
                            class="edit-input"
                          />
                        </span>
                        <el-date-picker
                          v-if="
                            editableFields_Types.description === 'DATE_TIME' &&
                            isCancelEditAll
                          "
                          style="margin-left: 4px;"
                          v-model="myDate"
                          type="datetime"
                          placeholder="Pick a date and time"
                          format="dd-MM-yyyy HH:mm:ss"
                          value-format="dd-MM-yyyy HH:mm:ss"
                          @change="handleDateChange"
                        />
                        <el-select
                          v-if="
                            editableFields_Types.description === 'SELECT' &&
                            isCancelEditAll
                          "
                          v-model="editableFields.description"
                          size="small"
                          style="margin-left: 4px;"
                        >
                          <el-option
                            v-for="option in options[
                              getFieldKey('description')
                            ]"
                            :value="option"
                            :key="option"
                          >
                            {{ option }}
                          </el-option>
                        </el-select>
                      </p>

                      <p
                        v-if="
                          getFieldKey('field1') && getFieldValue('field1', row)
                        "
                        class="card-description header"           
                      >
                        <span class="bold-key"
                          >{{ getFieldKey("field1") }} </span
                        >:
                        <span v-if="!isEditing.field1" class="card-data">{{
                          getFieldValue("field1", row) | truncate(140, "...")
                        }}</span>
                        <span v-else>
                          <input
                            v-if="
                              editableFields_Types.field1 !== 'SELECT' &&
                              editableFields_Types.field1 !== 'DATE_TIME'
                            "
                            v-model="editableFields.field1"
                            class="edit-input"
                          />
                        </span>
                        <el-select
                          v-if="
                            editableFields_Types.field1 === 'SELECT' &&
                            isCancelEditAll
                          "
                          v-model="editableFields.field1"
                          size="small"
                          style="margin-left: 4px;"
                        >
                          <el-option
                            v-for="option in options[getFieldKey('field1')]"
                            :value="option"
                            :key="option"
                          >
                            {{ option }}
                          </el-option>
                        </el-select>
                        <el-date-picker
                          v-if="
                            editableFields_Types.field1 === 'DATE_TIME' &&
                            isCancelEditAll
                          "
                          style="margin-left: 4px;"
                          v-model="myDate"
                          type="datetime"
                          placeholder="Pick a date and time"
                          format="dd-MM-yyyy HH:mm:ss"
                          value-format="dd-MM-yyyy HH:mm:ss"
                          @change="handleDateChange"
                        />
                      </p>
                      <p
                        v-if="
                          getFieldKey('field2') && getFieldValue('field2', row)
                        "
                        class="card-description header"
                      >
                        <span class="bold-key"
                          >{{ getFieldKey("field2") }} </span
                        >:
                        <span v-if="!isEditing.field2" class="card-data">{{
                          getFieldValue("field2", row) | truncate(140, "...")
                        }}</span>                
                        <span v-else>
                          <input
                            v-if="
                              editableFields_Types.field2 !== 'SELECT' &&
                              editableFields_Types.field2 !== 'DATE_TIME'
                            "
                            v-model="editableFields.field2"
                            class="edit-input"
                          />
                        </span>
                  
                        <el-select
                          v-if="
                            editableFields_Types.field2 === 'SELECT' &&
                            isCancelEditAll
                          "
                          v-model="editableFields.field2"
                          size="small"
                          style="margin-left: 4px;"
                        >
                        <el-option
                            v-for="option in options[getFieldKey('field2')]"
                            :value="option"
                            :key="option"
                          >
                            {{ option }}
                          </el-option>
                        </el-select>
                        <el-date-picker
                          v-if="
                            editableFields_Types.field2 === 'DATE_TIME' &&
                            isCancelEditAll
                          "
                          style="margin-left: 4px;"
                          v-model="myDate"
                          type="datetime"
                          placeholder="Pick a date and time"
                          format="dd-MM-yyyy HH:mm:ss"
                          value-format="dd-MM-yyyy HH:mm:ss"
                          @change="handleDateChange"
                        />
                      </p>
                      <p
                        v-if="
                          getFieldKey('field3') && getFieldValue('field3', row)
                        "
                        class="card-description header"
                      >
                        <span class="bold-key"
                          >{{ getFieldKey("field3") }} </span
                        >:
                        <span v-if="!isEditing.field3" class="card-data">{{
                          getFieldValue("field3", row) | truncate(140, "...")
                        }}</span>
                        <span v-else>
                          <input
                            v-if="
                              editableFields_Types.field3 !== 'SELECT' &&
                              editableFields_Types.field3 !== 'DATE_TIME'
                            "
                            v-model="editableFields.field3"
                            class="edit-input"
                          />
                        </span>
                        <el-select
                          v-if="
                            editableFields_Types.field3 === 'SELECT' &&
                            isCancelEditAll
                          "
                          style="margin-left: 4px;"
                          v-model="editableFields.field3"
                          size="small"
                        >
                          <el-option
                            v-for="option in options[getFieldKey('field3')]"
                            :value="option"
                            :key="option"
                          >
                            {{ option }}
                          </el-option>
                        </el-select>
                        <el-date-picker
                          v-if="
                            editableFields_Types.field3 === 'DATE_TIME' &&
                            isCancelEditAll
                          "
                          style="margin-left: 4px;"
                          v-model="myDate"
                          type="datetime"
                          placeholder="Pick a date and time"
                          format="dd-MM-yyyy HH:mm:ss"
                          value-format="dd-MM-yyyy HH:mm:ss"
                          @change="handleDateChange"
                        />
                      </p>
                      <br />
                      <template
                        v-if="
                          showFields.action_group_button &&
                          showFields.action_group_button.length
                        "
                      >
                        <div
                          v-for="(
                            action, index
                          ) in showFields.action_group_button"
                          :key="index"
                        >
                          <template v-if="hasHash(action)">
                            <action-button-execute
                              style="margin-top: 10px"
                              :data="{
                                ...getActionField(action),
                                parent_entityDataId: row._id,
                              }"
                              :form="form"
                              :is-view="false"
                              :hideStyles="true"
                            ></action-button-execute>
                          </template>
                          <template v-else>
                            <a
                              v-if="
                                IframePermissions('VIEW', 'EntityGalleryView')
                              "
                              href="javascript:void(0);"
                              class="card-link"
                              @click="viewWorkUserData(row, action)"
                              style="
                                color: rgb(64, 158, 255);
                                text-decoration: none;
                              "
                            >
                              {{ action }}
                            </a>
                          </template>
                        </div>
                      </template>
                      <template
                        v-if="
                          !showFields.action_group_button ||
                          !showFields.action_group_button.length
                        "
                      >
                        <el-link
                          v-if="IframePermissions('VIEW', 'EntityGalleryView')"
                          type="primary"
                          :underline="false"
                          class="card-link"
                          style="margin-bottom: 10px"
                          @click="viewWorkUserData(row)"
                        >
                          View details
                        </el-link>
                      </template>
                    </div>
                  </div>
                  <el-empty
                    v-else
                    :image-size="100"
                    description="Not configured"
                    class="entity-data-card"
                  ></el-empty>
                </div>
              </div>
              <div v-else>
                <el-empty description="No data"></el-empty>
              </div>
              <div
                class="cards"
                v-if="
                  Array.isArray(searchFilterData) &&
                  searchFilterData.length === 0 &&
                  searchQuery
                "
              >
                <el-empty description="No data"></el-empty>
              </div>
            </el-scrollbar>
          </div>
        </el-col>
        <!-- Third Block: Display selected email template content in full view -->
        <el-col style="width: 49vw; margin-right: 10px">
          <div
            v-for="(row, index) in searchQuery != ''
              ? searchFilterData
              : middleDivFilterData"
            :key="index"
            :span="14"
            style="
              max-height: 70vh;
              overflow-y: scroll;
              overflow-x: hidden;
              box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
              border-radius: 8px;
              transition: transform 0.2s, box-shadow 0.2s;
              border: 1px solid slategray;
            "
            class="card-container p-1"
          >
            <el-scrollbar wrap-style="max-height: calc(100vh - 180px)">
              <div
                v-if="
                  actionButtonFieldKeys &&
                  actionButtonFieldKeys.length &&
                  isRelationalDataFetched
                "
              >
                <div
                  v-for="actionKey in actionButtonFieldKeys"
                  :key="actionKey"
                  class="button-container pb-1"
                >
                  <ActionButtonExecute
                    v-if="row.entityData && actionKey.split('#') && actionKey.split('#').length"
                    :data="getActionButtonField(actionKey, row._id)"
                    :form="row.entityData[actionKey.split('#')[0]]"
                    :is-view="false"
                    :isView="false"
                    :workflowDataId="row._id"
                    :label="getActionButtonField(actionKey, row._id).label"
                    :entity_type="currentEntity.entity_type"
                    :entityDataId="row._id"
                    :entityId="currentEntity._id"
                    :entityData="row"
                    :checkIsDisabled="false"
                    :selectedTableRows="[]"
                    :fieldsData="
                      getCurrentTemplateFields(actionKey.split('#')[0])
                    "
                    :selfTemplateId="actionKey.split('#')[0]"
                    templateName="templateName"
                  />
                </div>
              </div>
              <div :key="row._id" class="card" v-if="getParsedData(row._id)">
                <p v-html="getParsedData(row._id)"></p>
              </div>
            </el-scrollbar>
          </div>
        </el-col>
      </el-row>
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import EntityHelper from "@/mixins/EntitiesHelper";
import TemplateBuilderhelper from "@/mixins/templateBuilderHelper.js";
import NavigationHelper from "@/mixins/navigationHelper";
import CustomDashboardConfig from "@/mixins/CustomDashboardHelper.js";
import ActionButtonExecute from "../../templates/formComponentsExecute/ActionButtonExecute.vue";
export default {
  name: "EntityEmailView",
  data() {
    return {
      isCancelEditAll: false,
      myDate: "",
      result: [],
      searchTerm: "",
      searchData: [],
      searchQuery: "",
      isSearch: false,
      filteredData: [],
      loading: false,
      totalData: [],
      limit: 10,
      filterColumList: [],
      initialPage: true,
      selectedEmailTemp: "",
      total: 0,
      entityRecords: [],
      offset: 0,
      data: [],
      entityFiltersData: {
        query_type: "AND",
        filters: [],
        filter_name: "",
        entity_id: "",
        isSaveFilter: false,
        columns_settings: [],
        quick_filters: [],
      },
      isSelect: false,
      applied_quick_filters: [],
      currentPage: 1,
      pageSize: 10,
      options: {},
      isEditing: {
        title: false,
        description: false,
        field1: false,
        field2: false,
        field3: false,
      },
      editableFields: {
        title: "",
        description: "",
        field1: "",
        field2: "",
        field3: "",
      },
      editableFields_Types: {
        title: "text",
        description: "text",
        field1: "text",
        field2: "text",
        field3: "text",
      },
      UpdatedData: {},
      fetchData: [],
      updatedKeyValueData: {},
      quickUpdateIndex: -1,
      selectedEntityFields: [],
      entity_id: "",
      emailFields: [],
      htmlStrings: [
        "<div class='content'>HTML Content 1. This is a long content block that will overflow and require scrolling within the card. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum et ligula in nunc bibendum fringilla a eu lectus.</div>",
        "<div class='content'>HTML Content 2. This content is not too long.</div>",
        "<div class='content'>HTML Content 3. Here is some more text that will need scrolling if the card height is too small.</div>",
        "<div class='content'>HTML Content 4. Short content.</div>",
        "<div class='content'>HTML Content 5. Another block of text that might overflow depending on the height setting. Lorem ipsum dolor sit amet, consectetur adipiscing elit.</div>",
      ],
      cardWidth: 30,
      selectedRecord: {},
      cardHeight: 300,
      selectedEmailTemplate: {},
      url_key: "",
      copyModal: false,
    };
  },
  components: {
    ActionButtonExecute,
  },
  props: [
    "entityId",
    "search_string",
    "showFields",
    "currentMenu",
    "isApplicationUserSideValid",
    "getSelfEntityFilters",
    "emailTemplates",
    "keyLabelData",
    "updateRow",
    "currentEntity",
    "quickUpdateRowData",
    "actionButtonFieldKeys",
    "parsedData",
    "isRelationalDataFetched",
    "allFieldsObj"
  ],
  mixins: [TemplateBuilderhelper, NavigationHelper, CustomDashboardConfig,EntityHelper],
  mounted() {
    this.data=this.parsedData
    let limit =
      this.pageSizes && this.pageSizes[0]
        ? this.pageSizes[0]
        : this.getPageSize;
    this.pageSize = limit;
    this.refreshPage = true;
    this.currentPage = parseInt(this.$route.query.page) || 1;
    this.pageSize = parseInt(this.$route.query.pageSize) || 10;
    this.UpdatedData = this.quickUpdateRowData;
    this.entity_id = this.$route.params.entity_id || this.entityId;
    this.handleStyles();
  },
  created() {
    this.myDate = new Date();
  },
  methods: {
    handleDateChange(value) {
      this.editableFields.field3 = value;
    },
    getParsedData(dataId) {
      return (
        this.parsedData?.find((data) => data._id == dataId)?.ejsTemplate || ""
      );
    },
    getCurrentTemplateFields(tempId) {
      return this.currentEntity.templates.find((e) => e.template_id == tempId)
        ?.templateInfo?.sections[0]?.fields;
    },
    getActionButtonField(actionKey, dataId) {
      let field = this.allFieldsObj[actionKey];
      return {
        ...field,
        parent_entityDataId: dataId,
        parent_entityId: this.currentEntity._id,
        key: field.key.includes("#") ? field.key.split("#")[1] : field.key,
      };
    },
    doubleClickUpdate(row) {
      const fieldsToUpdate = [
        "title",
        "description",
        "field1",
        "field2",
        "field3",
      ];
      fieldsToUpdate.forEach((field) => {
        const fieldValue = this.getFieldKey(field, row);
        if (this.keyLabelData && typeof this.keyLabelData === "object") {
          let fieldKey = null;
          for (const innerObject of Object.values(this.keyLabelData)) {
            if (innerObject && typeof innerObject === "object") {
              fieldKey = Object.keys(innerObject).find(
                (key) => innerObject[key] === fieldValue
              );
              if (fieldKey) break;
            }
          }
          if (fieldKey && this.editableFields[field] !== undefined) {
            let fieldUpdated = false;
            for (const templateKey of Object.keys(this.UpdatedData)) {
              const template = this.UpdatedData[templateKey];
              if (
                template &&
                Object.prototype.hasOwnProperty.call(template, fieldKey)
              ) {
                this.$set(
                  this.UpdatedData[templateKey],
                  fieldKey,
                  this.editableFields[field]
                );
                if (typeof this.UpdatedData[templateKey] !== "object") {
                  console.error(
                    "Template data is not an object:",
                    this.UpdatedData[templateKey]
                  );
                }
                fieldUpdated = true;
                break;
              }
            }
            if (!fieldUpdated) {
              const lastTemplateKey = Object.keys(this.UpdatedData).pop();
              if (lastTemplateKey) {
                this.$set(
                  this.UpdatedData[lastTemplateKey],
                  fieldKey,
                  this.editableFields[field]
                );
              } else {
                this.UpdatedData[`newTemplate`] = {
                  [fieldKey]: this.editableFields[field],
                };
              }
            }
          }
        } else {
          console.warn("keyLableData is not defined or is not an object");
        }
      });

      this.$emit("quickUpdateData", this.UpdatedData);
      this.$emit("updateRow", row);
    },
    cancelEditAll() {
      const fieldsToEdit = [
        "title",
        "description",
        "field1",
        "field2",
        "field3",
      ];

      fieldsToEdit.forEach((field) => {
        if (this.UpdatedData && this.UpdatedData[field]) {
          this.$set(this.editableFields, field, this.UpdatedData[field]);
        } else {
          this.$set(this.editableFields, field, "");
        }
        this.isEditing = {
          title: false,
          description: false,
          field1: false,
          field2: false,
          field3: false,
        };
        this.$set(this.isEditing, field, false);
        this.editableFields = {
          title: "",
          description: "",
          field1: "",
          field2: "",
          field3: "",
        };
      });
      this.isCancelEditAll = false;
    },

    cellDoubleClicked(row) {
      this.isCancelEditAll = true;
      this.isEditing = {
        title: true,
        description: true,
        field1: true,
        field2: true,
        field3: true,
      };
      const fields = this.currentEntity.templates[0].templateInfo.sections[0].fields;
        fields.forEach((field) => {
          if (field && 'options' in field) {
            const cleanedLabel = field.label.replace(/Tickets Tracker -/gi, '').trim();
            this.options = {
              ...this.options,
              [cleanedLabel]: field.options
            };
          }
        });
      for (const [, value] of Object.entries(
        this.currentEntity?.settings || {}
      )) {
        if (value.type === "AUTO_INCREMENT_NUMBER") {
          if (value.label === this.getFieldKey("title")) {
            this.isEditing.title = false;
          } else if (value.id === this.getFieldKey("description")) {
            this.isEditing.description = false;
          } else if (value.id === this.getFieldKey("field1")) {
            this.isEditing.field1 = false;
          } else if (value.id === this.getFieldKey("field2")) {
            this.isEditing.field2 = false;
          } else if (value.id === this.getFieldKey("field3")) {
            this.isEditing.field3 = false;
          }
        }
        if (value.type === "DATE_TIME") {
          if (value.label === this.getFieldKey("title")) {
            this.editableFields.title = this.myDate;
            this.editableFields_Types.title = "DATE_TIME";
          } else if (value.label === this.getFieldKey("description")) {
            this.editableFields.description = this.myDate;
            this.editableFields_Types.description = "DATE_TIME";
          } else if (value.label === this.getFieldKey("field1")) {
            this.editableFields.field1 = this.myDate;
            this.editableFields_Types.field1 = "DATE_TIME";
          } else if (value.label === this.getFieldKey("field2")) {
            this.editableFields.field2 = this.myDate;
            this.editableFields_Types.field2 = "DATE_TIME";
          } else if (value.label === this.getFieldKey("field3")) {
            this.editableFields.field3 = this.myDate;
            this.editableFields_Types.field3 = "DATE_TIME";
          }
        }
        if (value.type === "SELECT") {
          if (value.label === this.getFieldKey("title")) {
            this.editableFields.title = this.options[value.label];
            this.editableFields_Types.title = "SELECT";
          }
          if (value.label === this.getFieldKey("description")) {
            this.editableFields.description = this.options[value.label];
            this.editableFields_Types.description = "SELECT";
          }
          if (value.label === this.getFieldKey("field1")) {
            this.editableFields.field1 = this.options[value.label];
            this.editableFields_Types.field1 = "SELECT";
          }
          if (value.label === this.getFieldKey("field2")) {
            this.editableFields.field2 = this.options[value.label];
            this.editableFields_Types.field2 = "SELECT";
          }
          if (value.label === this.getFieldKey("field3")) {
            this.editableFields.field3 = this.options[value.label];
            this.editableFields_Types.field3 = "SELECT";
          }
        }
      }
      this.quickUpdateIndex = this.data.findIndex(
        (e) => e._id.toString() == row._id.toString()
      );
      this.UpdatedData = null;
      this.UpdatedData = JSON.parse(JSON.stringify(row.entityData));
      if (
        this.currentEntity?.templates.length !==
        Object.keys(row.entityData).length
      ) {
        this.currentEntity?.templates.map((tmp) => {
          if (!Object.keys(this.UpdatedData).includes(tmp.template_id)) {
            let emptyTemplateDataObj = {};
            tmp.templateInfo.sections[0].fields.map((fd) => {
              emptyTemplateDataObj[fd.key] = "";
            });
            this.UpdatedData[tmp.template_id] = emptyTemplateDataObj;
          }
        });
      }
      this.editableFields = {
        title: this.getFieldValue("title", row),
        description: this.getFieldValue("description", row),
        field1: this.getFieldValue("field1", row),
        field2: this.getFieldValue("field2", row),
        field3: this.getFieldValue("field3", row),
      };
    },
    hasHash(value) {
      return value.includes("#");
    },
    async viewWorkUserData(row, action = "View") {
      if (row._id) {
        let path = "";

        if (action === "View" || action === "Edit") {
          path = await this.determinePath(action);
        } else {
          this.performAnotherAction(action, row);
          return;
        }
        if (path) {
          let query = this.getNavigationQuery(this.$route.query);
          query = {
            ...query,
            dataId: row._id,
            filter: this.currentFilter,
            menu_id: this.$route.params.menuId,
          };
          if (action === "Edit" && this.isApplicationUserSide) {
            query.layout = "viewType";
            query.page = this.$route.query.page || 1;
            query.pageSize = this.$route.query.pageSize || 10;
            query.type = "AUfYWb7SC6U=";
          }
          if (action === "View" && this.isApplicationUserSide) {
            query.layout = "viewType";
            query.page = this.$route.query.page || 1;
            query.type = "AUfYWb7SC6U=";
          }
          this.$router.push({
            path: path,
            query: query,
          });
        }
      }
    },
    async performAnotherAction(action, row) {
      const matchingAction = this.customization?.actions.find(
        (item) => item.label === action
      );
      if (matchingAction) {
        this.quickActionDummyField = {
          label: matchingAction.label,
          parent_entityId: this.currentEntity._id,
          parent_entityDataId: row._id,
          action_button_type: "QUICK_ACTION",
          actions: [
            {
              action_button_action_type: "ESTABLISH_RELATIONSHIP",
              relational_entity_one: matchingAction.relationship,
              action_button_relationship_type:
                this.quickActionButtonType[matchingAction.type],
            },
          ],
          type: matchingAction.type,
          execute: true,
        };
        this.quickActionModal = true;
      }
    },
    async determinePath(action) {
      let path = "";
      if (action === "View") {
        if (this.isApplicationUserSideValid) {
          path = `/ap/entity-view/${this.entity_id}`;
        } else {
          path =
            this.getIframeUrlInfo != null
              ? `/if/${this.getIframeUrlInfo._id}/entity/view/${this.entity_id}`
              : `/entity/view/${this.entity_id}`;
        }
      } else if (action === "Edit") {
        if (this.isApplicationUserSideValid) {
          path = `/ap/entity-execute/${this.entity_id}`;
        } else {
          path =
            this.getIframeUrlInfo != null
              ? `/if/${this.getIframeUrlInfo._id}/entity/edit/${this.entity_id}`
              : `/entity/edit/${this.entity_id}`;
        }
      }
      return path;
    },
    getFieldKey(field) {
      if (!this.keyLabelData || !Object.keys(this.keyLabelData).length) {
        console.warn("keyLabelData is undefined or empty");
        return null;
      }
      let firstObjectKey = Object.keys(this.keyLabelData)[0];
      if (this.showFields[field]) {
        let [, key] = this.showFields[field].split("#");
        if (this.keyLabelData[firstObjectKey][key]) {
          return this.keyLabelData[firstObjectKey][key];
        }
      }
      return null;
    },
    getFieldValue(field, data) {
      if (!data || !data.entityData) {
        return "";
      }
      let firstObject = Object.keys(data.entityData)[0];
      if (!firstObject) {
        return "";
      }
      if (
        "date_time" in (data.entityData[firstObject] || {}) &&
        this.showFields[field]?.split("#")[1] === "date_time" &&
        data.entityData[firstObject]["date_time"]
      ) {
        const formattedDate = this.formatDate(
          data.entityData[firstObject]["date_time"]
        );
        return formattedDate;
      }
      if (field === "action_group_button") {
        let quickActions = [];
        if (this.showFields[field]?.length > 0) {
          quickActions = this.showFields[field].filter(
            (item) => !item.includes("#")
          );
          if (quickActions.length > 0) {
            return quickActions.map((item) => item);
          }
          return "";
        }
      } else if (
        data.entityData &&
        this.showFields[field] &&
        this.showFields[field].includes("#")
      ) {
        let [temp, key] = this.showFields[field]?.split("#") || [];
        if (temp && key) {
          if (data.entityData[temp] && data.entityData[temp][key]) {
            return (
              data.entityData[temp][key + "/name"] || data.entityData[temp][key]
            );
          }
        }
        return "";
      } else {
        if (this.currentEntity === "INDIVIDUAL") {
          if (
            field === "profile" &&
            data.avatar &&
            this.showFields[field] === "defaultpic"
          ) {
            return data.avatar;
          }
        }
        return "";
      }
    },
    handleStyles() {
      if (this.showFields?.columnsPerRow) {
        switch (this.showFields.columnsPerRow) {
          case 1:
            this.cardWidth = 100;
            this.cardHeight = 600;
            break;
          case 2:
            this.cardWidth = 45;
            this.cardHeight = 400;
            break;
          case 3:
            this.cardWidth = 32;
            this.cardHeight = 300;
            break;
        }
      }
    },
    selectedCard(dt) {
      this.selectedRecord = dt;
    },
    checkEmailTemplateExists() {
      return this.selectedEmailTemplate;
    },
  },
  computed: {
    ...mapGetters("entities", [
      "getEntityDataById",
      "getEntityDataByEntityId",
      "getEntityDataUpdateStatus",
      "getEntityDocumentsDataStatus",
      "getEntityDocumentsData",
      "getEntityRecordsForTable",
      "getUploadCSVfileStatus",
      "getupdateMappedFieldStatus",
      "getmappedFieldsData",
      "getuploadedFilesData",
      "getstoreUploadedFileStatus",
      "getimportMappedDataStatusUpdatedVersion",
      "getentitydataBulkDeleteStatus",
      "getEntitydataBulkDeleteErrors",
      "getEntityShowFields",
    ]),
    ...mapGetters("navigationOpen", [
      "getIsOpen",
      "getIsCollapse",
      "getIsMobile",
    ]),
    firstBlockData(){
    return this.data
    },
    searchFilterData() {
      if (this.searchQuery !== "") {
        if (this.selectedRecord) {
          return this.searchData.filter(
            (row) => row._id === this.selectedRecord._id
          );
        } else {
          return this.searchData;
        }
      }
      return [];
    },

    middleDivFilterData() {
      if (this.selectedRecord) {
        return this.data.filter((row) => row._id === this.selectedRecord._id);
      }
      return this.data;
    },
  },
  watch: {
   data(newData) {
    if (newData && newData.length > 0) {
      this.selectedRecord = newData[0];  // Update selectedRecord when data changes
    }
  },
    paginatedData() {
      if (this.searchData.length > 0) {
        this.selectedRecord = this.searchData[0];
        return this.searchData;
      }
    },
    showFields: {
      handler: function () {
        if (
          JSON.stringify(this.showFields) != JSON.stringify(this.showFields)
        ) {
          this.showFields = { ...this.showFields };
        }
      },
      deep: true,
    },
    "showFields.columnsPerRow": {
      async handler() {
        await this.handleStyles();
      },
      deep: true,
    },
    "showFields.selected_email_template": {
      async handler() {
        this.selectedEmailTemplate = this.showFields.selected_email_template;
      },
      deep: true,
    },
    isRelationalDataFetched: {
      handler: function () {
        // console.log("isRelationalDataFetched", this.isRelationalDataFetched);
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
.card-description{
  display: flex;
}
.bold-key {
  font-weight: 700;
}
.edit-input {
  width: 80%;
  margin-right: 5px;
  padding: 8px;
  border: 1px solid #dcdfe6;
  color: #606266;
  margin-left: 4px;
  margin-top: 2px;
}
.edit-icons {
  display: inline-block;
}

.tick-icon {
  color: green;
  cursor: pointer;
  margin-right: 5px;
}

.cross-icon {
  color: red;
  cursor: pointer;
}
.card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 16px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.card {
  background-color: #ffffff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s, box-shadow 0.2s;
  padding: 16px;
  width: 46.5vw;
  overflow-x: hidden;
}
.card-body {
  border-radius: 10px;
  background: #ffff;
  overflow-wrap: break-word;
}
.card-profile {
  margin-left: 60px;
  margin-top: 10px;
  /* margin-right:60px ; */
}
.content {
  font-size: 14px;
  line-height: 1.5;
  color: #333;
}

.size-selector {
  margin-bottom: 16px;
}

.size-selector label {
  font-weight: bold;
  margin-right: 8px;
}

.size-selector input {
  margin-right: 8px;
}
.entity-data-card {
  background: #ffffff;
  box-shadow: 0 0px 10px 2px rgba(27, 72, 126, 0.1294117647) !important;
  border-radius: 10px;
  min-height: 200px;
  border: 1px solid slategray;
  margin-left: 10px;
  margin-right: 10px;
}

@media (max-width: 768px) {
  .card-container {
    justify-content: center;
  }

  .card {
    flex: 0 1 100%;
  }
}

@media (max-width: 1200px) {
  .card {
    flex: 0 1 48%;
  }
}

@media (min-width: 1201px) {
  .card {
    flex: 0 1 30%;
  }
}

.card-summary {
  max-height: 10vh;
}

.summary {
  min-width: 5vw;
  min-height: 10vh;
  overflow-y: auto;
  border-radius: 5px;
  margin-bottom: 5px;
  padding: 5px;
  background-color: #ffffff;
  border: 1px solid #dcdfe6;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.card-details {
  max-height: 30vh;
  width: 15vw;
}
.edit-icons {
  align-content: center;
  align-items: center;
  float: right;
  margin: 5px;
}
.details {
  max-height: 40vh;
  overflow-y: hidden;
  background-color: ffffff;
  border: 1px slategray solid;
  border-radius: 5px;
  margin-bottom: 5px;
}
.button-container {
  display: flex;
  justify-content: end;
}
</style>
