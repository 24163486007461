var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',[(!_vm.checkEmailTemplateExists())?_c('div',[_vm._v("Please Select Template")]):_c('div',[(this.currentEntity.viewType != 'HTML_CONTENT')?_c('div',{staticClass:"size-selector"},[_c('label',{attrs:{"for":"cardHeight"}},[_vm._v("Card Height:")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.cardHeight),expression:"cardHeight"}],attrs:{"type":"range","id":"cardHeight","min":"100","max":"700"},domProps:{"value":(_vm.cardHeight)},on:{"__r":function($event){_vm.cardHeight=$event.target.value}}}),_c('span',[_vm._v(_vm._s(_vm.cardHeight)+"px")])]):_vm._e(),_c('el-row',{staticStyle:{"display":"flex","flex-direction":"row"}},[_c('el-col',{staticStyle:{"overflow-y":"auto"},attrs:{"span":4}},[_c('el-scrollbar',{attrs:{"wrap-style":"min-height: 70vh;overflow-y: auto;"}},[_c('div',{staticClass:"card-summary"},_vm._l((!_vm.searchQuery
                ? _vm.firstBlockData
                : this.searchData),function(template,index){return _c('div',{key:index,staticClass:"summary",style:({
                backgroundColor: _vm.selectedRecord._id === template._id ? '#0091D5' : 'white',
                color: _vm.selectedRecord._id === template._id ? 'white' : ''
              }),on:{"click":function($event){return _vm.selectedCard(template)}}},[(template.field === 'title')?_c('div',[_c('span',[_vm._v("Title:")]),_vm._v(_vm._s(template.fieldValue)+" ")]):(template.field === 'description')?_c('div',[_c('span',[_vm._v("Description:")]),_vm._v(_vm._s(template.fieldValue)+" ")]):_c('div',[(
                    _vm.getFieldKey('title') && _vm.getFieldValue('title', template)
                  )?_c('div',[_c('span',{staticClass:"bold-key"},[_vm._v(_vm._s(_vm.getFieldKey("title"))+" ")]),_vm._v(": "),_c('span',{staticClass:"card-data"},[_vm._v(_vm._s(_vm._f("truncate")(_vm.getFieldValue("title", template),140, "...")))])]):_vm._e(),(
                    _vm.getFieldKey('description') &&
                    _vm.getFieldValue('description', template)
                  )?_c('div',[_c('span',{staticClass:"bold-key"},[_vm._v(_vm._s(_vm.getFieldKey("description"))+" ")]),_vm._v(": "),_c('span',{staticClass:"card-data"},[_vm._v(_vm._s(_vm._f("truncate")(_vm.getFieldValue("description", template),140, "...")))])]):_vm._e()])])}),0)])],1),_c('el-col',{staticStyle:{"max-height":"60vh"},attrs:{"span":6}},[_c('div',{staticStyle:{"overflow-y":"hidden"}},[_c('el-scrollbar',{attrs:{"wrap-style":"max-height: calc(100vh - 180px)"}},[(
                _vm.selectedRecord &&
                (_vm.searchQuery != '' || _vm.middleDivFilterData.length)
              )?_c('div',{staticClass:"cards"},_vm._l((_vm.searchQuery != ''
                  ? _vm.searchFilterData
                  : _vm.middleDivFilterData),function(row,index){return _c('div',{key:index,staticStyle:{"overflow-x":"hidden"}},[(
                    _vm.getProfilePicture(row) ||
                    _vm.getFieldValue('profile', row) ||
                    _vm.getFieldValue('title', row) ||
                    _vm.getFieldValue('description', row) ||
                    _vm.getFieldValue('field1', row) ||
                    _vm.getFieldValue('field2', row) ||
                    _vm.getFieldValue('field3', row)
                  )?_c('div',{staticClass:"entity-data-card",on:{"dblclick":function($event){return _vm.cellDoubleClicked(row)}}},[(
                      _vm.isEditing.title ||
                      _vm.isEditing.description ||
                      _vm.isEditing.field1 ||
                      _vm.isEditing.field2 ||
                      _vm.isEditing.field3
                    )?_c('span',{staticClass:"edit-icons"},[_c('i',{staticClass:"tick-icon",on:{"click":function($event){return _vm.doubleClickUpdate(row)}}},[_vm._v("✔️")]),_c('i',{staticClass:"cross-icon",on:{"click":_vm.cancelEditAll}},[_vm._v("❌")])]):_vm._e(),_c('div',{staticClass:"card-body"},[(
                        _vm.getFieldKey('title') && _vm.getFieldValue('title', row)
                      )?_c('p',{staticClass:"card-title",staticStyle:{"display":"flex"}},[_c('span',{staticClass:"bold-key"},[_vm._v(_vm._s(_vm.getFieldKey("title"))+" ")]),_vm._v(": "),(!_vm.isEditing.title)?_c('span',{staticClass:"card-data"},[_vm._v(_vm._s(_vm._f("truncate")(_vm.getFieldValue("title", row),140, "...")))]):_c('span',[(
                            _vm.editableFields_Types.title !== 'SELECT' &&
                            _vm.editableFields_Types.title !== 'DATE_TIME'
                          )?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.editableFields.title),expression:"editableFields.title"}],staticClass:"edit-input",domProps:{"value":(_vm.editableFields.title)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.editableFields, "title", $event.target.value)}}}):_vm._e()]),(
                          _vm.editableFields_Types.title === 'SELECT' &&
                          _vm.isCancelEditAll
                        )?_c('el-select',{staticStyle:{"margin-left":"4px"},attrs:{"size":"small"},model:{value:(_vm.editableFields.title),callback:function ($$v) {_vm.$set(_vm.editableFields, "title", $$v)},expression:"editableFields.title"}},_vm._l((_vm.options[_vm.getFieldKey('title')]),function(option){return _c('el-option',{key:option,attrs:{"value":option}},[_vm._v(" "+_vm._s(option)+" ")])}),1):_vm._e(),(
                          _vm.editableFields_Types.title === 'DATE_TIME' &&
                          _vm.isCancelEditAll
                        )?_c('el-date-picker',{staticStyle:{"margin-left":"4px"},attrs:{"type":"datetime","placeholder":"Pick a date and time","format":"dd-MM-yyyy HH:mm:ss","value-format":"dd-MM-yyyy HH:mm:ss"},on:{"change":_vm.handleDateChange},model:{value:(_vm.myDate),callback:function ($$v) {_vm.myDate=$$v},expression:"myDate"}}):_vm._e()],1):_vm._e(),(
                        _vm.getFieldKey('description') &&
                        _vm.getFieldValue('description', row)
                      )?_c('p',{staticClass:"card-description header",staticStyle:{"display":"flex"}},[_c('span',{staticClass:"bold-key"},[_vm._v(_vm._s(_vm.getFieldKey("description"))+" ")]),_vm._v(": "),(!_vm.isEditing.description)?_c('span',{staticClass:"card-data"},[_vm._v(_vm._s(_vm._f("truncate")(_vm.getFieldValue("description", row),140, "...")))]):_c('span',[(
                            _vm.editableFields_Types.description !== 'SELECT' &&
                            _vm.editableFields_Types.description !== 'DATE_TIME'
                          )?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.editableFields.description),expression:"editableFields.description"}],staticClass:"edit-input",domProps:{"value":(_vm.editableFields.description)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.editableFields, "description", $event.target.value)}}}):_vm._e()]),(
                          _vm.editableFields_Types.description === 'DATE_TIME' &&
                          _vm.isCancelEditAll
                        )?_c('el-date-picker',{staticStyle:{"margin-left":"4px"},attrs:{"type":"datetime","placeholder":"Pick a date and time","format":"dd-MM-yyyy HH:mm:ss","value-format":"dd-MM-yyyy HH:mm:ss"},on:{"change":_vm.handleDateChange},model:{value:(_vm.myDate),callback:function ($$v) {_vm.myDate=$$v},expression:"myDate"}}):_vm._e(),(
                          _vm.editableFields_Types.description === 'SELECT' &&
                          _vm.isCancelEditAll
                        )?_c('el-select',{staticStyle:{"margin-left":"4px"},attrs:{"size":"small"},model:{value:(_vm.editableFields.description),callback:function ($$v) {_vm.$set(_vm.editableFields, "description", $$v)},expression:"editableFields.description"}},_vm._l((_vm.options[
                            _vm.getFieldKey('description')
                          ]),function(option){return _c('el-option',{key:option,attrs:{"value":option}},[_vm._v(" "+_vm._s(option)+" ")])}),1):_vm._e()],1):_vm._e(),(
                        _vm.getFieldKey('field1') && _vm.getFieldValue('field1', row)
                      )?_c('p',{staticClass:"card-description header"},[_c('span',{staticClass:"bold-key"},[_vm._v(_vm._s(_vm.getFieldKey("field1"))+" ")]),_vm._v(": "),(!_vm.isEditing.field1)?_c('span',{staticClass:"card-data"},[_vm._v(_vm._s(_vm._f("truncate")(_vm.getFieldValue("field1", row),140, "...")))]):_c('span',[(
                            _vm.editableFields_Types.field1 !== 'SELECT' &&
                            _vm.editableFields_Types.field1 !== 'DATE_TIME'
                          )?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.editableFields.field1),expression:"editableFields.field1"}],staticClass:"edit-input",domProps:{"value":(_vm.editableFields.field1)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.editableFields, "field1", $event.target.value)}}}):_vm._e()]),(
                          _vm.editableFields_Types.field1 === 'SELECT' &&
                          _vm.isCancelEditAll
                        )?_c('el-select',{staticStyle:{"margin-left":"4px"},attrs:{"size":"small"},model:{value:(_vm.editableFields.field1),callback:function ($$v) {_vm.$set(_vm.editableFields, "field1", $$v)},expression:"editableFields.field1"}},_vm._l((_vm.options[_vm.getFieldKey('field1')]),function(option){return _c('el-option',{key:option,attrs:{"value":option}},[_vm._v(" "+_vm._s(option)+" ")])}),1):_vm._e(),(
                          _vm.editableFields_Types.field1 === 'DATE_TIME' &&
                          _vm.isCancelEditAll
                        )?_c('el-date-picker',{staticStyle:{"margin-left":"4px"},attrs:{"type":"datetime","placeholder":"Pick a date and time","format":"dd-MM-yyyy HH:mm:ss","value-format":"dd-MM-yyyy HH:mm:ss"},on:{"change":_vm.handleDateChange},model:{value:(_vm.myDate),callback:function ($$v) {_vm.myDate=$$v},expression:"myDate"}}):_vm._e()],1):_vm._e(),(
                        _vm.getFieldKey('field2') && _vm.getFieldValue('field2', row)
                      )?_c('p',{staticClass:"card-description header"},[_c('span',{staticClass:"bold-key"},[_vm._v(_vm._s(_vm.getFieldKey("field2"))+" ")]),_vm._v(": "),(!_vm.isEditing.field2)?_c('span',{staticClass:"card-data"},[_vm._v(_vm._s(_vm._f("truncate")(_vm.getFieldValue("field2", row),140, "...")))]):_c('span',[(
                            _vm.editableFields_Types.field2 !== 'SELECT' &&
                            _vm.editableFields_Types.field2 !== 'DATE_TIME'
                          )?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.editableFields.field2),expression:"editableFields.field2"}],staticClass:"edit-input",domProps:{"value":(_vm.editableFields.field2)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.editableFields, "field2", $event.target.value)}}}):_vm._e()]),(
                          _vm.editableFields_Types.field2 === 'SELECT' &&
                          _vm.isCancelEditAll
                        )?_c('el-select',{staticStyle:{"margin-left":"4px"},attrs:{"size":"small"},model:{value:(_vm.editableFields.field2),callback:function ($$v) {_vm.$set(_vm.editableFields, "field2", $$v)},expression:"editableFields.field2"}},_vm._l((_vm.options[_vm.getFieldKey('field2')]),function(option){return _c('el-option',{key:option,attrs:{"value":option}},[_vm._v(" "+_vm._s(option)+" ")])}),1):_vm._e(),(
                          _vm.editableFields_Types.field2 === 'DATE_TIME' &&
                          _vm.isCancelEditAll
                        )?_c('el-date-picker',{staticStyle:{"margin-left":"4px"},attrs:{"type":"datetime","placeholder":"Pick a date and time","format":"dd-MM-yyyy HH:mm:ss","value-format":"dd-MM-yyyy HH:mm:ss"},on:{"change":_vm.handleDateChange},model:{value:(_vm.myDate),callback:function ($$v) {_vm.myDate=$$v},expression:"myDate"}}):_vm._e()],1):_vm._e(),(
                        _vm.getFieldKey('field3') && _vm.getFieldValue('field3', row)
                      )?_c('p',{staticClass:"card-description header"},[_c('span',{staticClass:"bold-key"},[_vm._v(_vm._s(_vm.getFieldKey("field3"))+" ")]),_vm._v(": "),(!_vm.isEditing.field3)?_c('span',{staticClass:"card-data"},[_vm._v(_vm._s(_vm._f("truncate")(_vm.getFieldValue("field3", row),140, "...")))]):_c('span',[(
                            _vm.editableFields_Types.field3 !== 'SELECT' &&
                            _vm.editableFields_Types.field3 !== 'DATE_TIME'
                          )?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.editableFields.field3),expression:"editableFields.field3"}],staticClass:"edit-input",domProps:{"value":(_vm.editableFields.field3)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.editableFields, "field3", $event.target.value)}}}):_vm._e()]),(
                          _vm.editableFields_Types.field3 === 'SELECT' &&
                          _vm.isCancelEditAll
                        )?_c('el-select',{staticStyle:{"margin-left":"4px"},attrs:{"size":"small"},model:{value:(_vm.editableFields.field3),callback:function ($$v) {_vm.$set(_vm.editableFields, "field3", $$v)},expression:"editableFields.field3"}},_vm._l((_vm.options[_vm.getFieldKey('field3')]),function(option){return _c('el-option',{key:option,attrs:{"value":option}},[_vm._v(" "+_vm._s(option)+" ")])}),1):_vm._e(),(
                          _vm.editableFields_Types.field3 === 'DATE_TIME' &&
                          _vm.isCancelEditAll
                        )?_c('el-date-picker',{staticStyle:{"margin-left":"4px"},attrs:{"type":"datetime","placeholder":"Pick a date and time","format":"dd-MM-yyyy HH:mm:ss","value-format":"dd-MM-yyyy HH:mm:ss"},on:{"change":_vm.handleDateChange},model:{value:(_vm.myDate),callback:function ($$v) {_vm.myDate=$$v},expression:"myDate"}}):_vm._e()],1):_vm._e(),_c('br'),(
                        _vm.showFields.action_group_button &&
                        _vm.showFields.action_group_button.length
                      )?_vm._l((_vm.showFields.action_group_button),function(action,index){return _c('div',{key:index},[(_vm.hasHash(action))?[_c('action-button-execute',{staticStyle:{"margin-top":"10px"},attrs:{"data":{
                              ..._vm.getActionField(action),
                              parent_entityDataId: row._id,
                            },"form":_vm.form,"is-view":false,"hideStyles":true}})]:[(
                              _vm.IframePermissions('VIEW', 'EntityGalleryView')
                            )?_c('a',{staticClass:"card-link",staticStyle:{"color":"rgb(64, 158, 255)","text-decoration":"none"},attrs:{"href":"javascript:void(0);"},on:{"click":function($event){return _vm.viewWorkUserData(row, action)}}},[_vm._v(" "+_vm._s(action)+" ")]):_vm._e()]],2)}):_vm._e(),(
                        !_vm.showFields.action_group_button ||
                        !_vm.showFields.action_group_button.length
                      )?[(_vm.IframePermissions('VIEW', 'EntityGalleryView'))?_c('el-link',{staticClass:"card-link",staticStyle:{"margin-bottom":"10px"},attrs:{"type":"primary","underline":false},on:{"click":function($event){return _vm.viewWorkUserData(row)}}},[_vm._v(" View details ")]):_vm._e()]:_vm._e()],2)]):_c('el-empty',{staticClass:"entity-data-card",attrs:{"image-size":100,"description":"Not configured"}})],1)}),0):_c('div',[_c('el-empty',{attrs:{"description":"No data"}})],1),(
                Array.isArray(_vm.searchFilterData) &&
                _vm.searchFilterData.length === 0 &&
                _vm.searchQuery
              )?_c('div',{staticClass:"cards"},[_c('el-empty',{attrs:{"description":"No data"}})],1):_vm._e()])],1)]),_c('el-col',{staticStyle:{"width":"49vw","margin-right":"10px"}},_vm._l((_vm.searchQuery != ''
            ? _vm.searchFilterData
            : _vm.middleDivFilterData),function(row,index){return _c('div',{key:index,staticClass:"card-container p-1",staticStyle:{"max-height":"70vh","overflow-y":"scroll","overflow-x":"hidden","box-shadow":"0 2px 4px rgba(0, 0, 0, 0.1)","border-radius":"8px","transition":"transform 0.2s, box-shadow 0.2s","border":"1px solid slategray"},attrs:{"span":14}},[_c('el-scrollbar',{attrs:{"wrap-style":"max-height: calc(100vh - 180px)"}},[(
                _vm.actionButtonFieldKeys &&
                _vm.actionButtonFieldKeys.length &&
                _vm.isRelationalDataFetched
              )?_c('div',_vm._l((_vm.actionButtonFieldKeys),function(actionKey){return _c('div',{key:actionKey,staticClass:"button-container pb-1"},[(row.entityData && actionKey.split('#') && actionKey.split('#').length)?_c('ActionButtonExecute',{attrs:{"data":_vm.getActionButtonField(actionKey, row._id),"form":row.entityData[actionKey.split('#')[0]],"is-view":false,"isView":false,"workflowDataId":row._id,"label":_vm.getActionButtonField(actionKey, row._id).label,"entity_type":_vm.currentEntity.entity_type,"entityDataId":row._id,"entityId":_vm.currentEntity._id,"entityData":row,"checkIsDisabled":false,"selectedTableRows":[],"fieldsData":_vm.getCurrentTemplateFields(actionKey.split('#')[0]),"selfTemplateId":actionKey.split('#')[0],"templateName":"templateName"}}):_vm._e()],1)}),0):_vm._e(),(_vm.getParsedData(row._id))?_c('div',{key:row._id,staticClass:"card"},[_c('p',{domProps:{"innerHTML":_vm._s(_vm.getParsedData(row._id))}})]):_vm._e()])],1)}),0)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }